import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';

const action = "https://webhook.teambooster.io"

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class ContactSplit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject: '',
      text: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { target: { name, value } } = event
    this.setState({ [name]: value })
  }

  handleSubmit(event) {
    event.preventDefault();

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    
    fetch(action, {
      method: 'post',
      headers,
      body: JSON.stringify(this.state)
    }).then((response) => {
      if(response.status === 200) {
        this.setState({
          name: '',
          email: '',
          subject: '',
          text: ''
        })
        alert("Thank you for getting in touch. We will come back to you as soon as possible.")
      } else {
        alert("An error occured. Please try again later.")
      }
    })
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Contact Us',
      paragraph: 'Fill the following form to get in touch with us.'
    };
    
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-top" />
            <div className={splitClasses}>
              <form onSubmit={this.handleSubmit}>
                <fieldset>
                  <div className="mb-12">
                    <Input
                      value={this.state.name} onChange={this.handleChange}
                      name="name"
                      label="Full name"
                      type="text"
                      placeholder="Full name" 
                      labelHidden
                      required />
                  </div>
                  <div className="mb-12">
                    <Input
                      value={this.state.email} onChange={this.handleChange}                   
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="Email"
                      labelHidden
                      required />
                  </div>
                  <div className="mb-12">
                    <Input
                      value={this.state.subject} onChange={this.handleChange}
                      name="subject"
                      label="Subject"
                      placeholder="Subject"
                      labelHidden
                      required />
                  </div>                  
                  <div className="mb-12">
                    <Input
                      value={this.state.message} onChange={this.handleChange}
                      name="message"
                      type="textarea"
                      label="Message"
                      placeholder="Message"
                      labelHidden
                      required />
                  </div>
                  <div className="mt-24 mb-32">
                    <Button type="submit" color="primary" wide>Submit</Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ContactSplit.propTypes = propTypes;
ContactSplit.defaultProps = defaultProps;

export default ContactSplit;